.parameter-form {

    .input-group {
        align-items: center;

        > input {
            margin-left: auto;
            min-width: 100px;
        }

        .input-group-append {
            width: 150px;
        }
    }
}
@import "./assets/scss/app.scss";
@import "./assets/scss/views/_parameters.scss";

.ck-balloon-panel{
  z-index:999999 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.rdw-editor-toolbar {
  &.heading-item {
    display: none;
  }

  .rdw-inline-wrapper {
    [title="Underline"],
    [title="Strikethrough"],
    [title="Monospace"],
    [title="Subscript"],
    [title="Superscript"] {
      display: none;
    }
  }

  .rdw-fontfamily-wrapper,
  .rdw-fontsize-wrapper,
  .rdw-text-align-wrapper,
  .rdw-colorpicker-wrapper,
  .rdw-emoji-wrapper,
  .rdw-image-wrapper,
  .rdw-remove-wrapper,
  .rdw-embedded-wrapper {
    display: none;
  }

  .rdw-option-wrapper {
    &[title="Unlink"] {
      display: none;
    }
  }

  .rdw-list-wrapper {
    [title="Indent"],
    [title="Outdent"] {
      display: none;
    }
  }

  .rdw-dropdown-optionwrapper {
    > li {
      &:nth-of-type(2),
      &:nth-last-child(1),
      &:nth-last-child(2) {
        display: none;
      }
    }
  }
}
